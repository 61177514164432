export default {
  production: {
    api: "https://village.hemeixiangcun.xn--fiqs8s",
    // api: "http://192.168.10.45:8700/",

  },
  development: {
    api: "https://village.hemeixiangcun.xn--fiqs8s",
    // api: "http://192.168.10.45:8700/",

  },
};
